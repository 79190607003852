<template>
<v-card elevation="0">
    <v-card-text>
    <v-data-table :headers="headers"
        :items="vouchers"
        sort-by="name"
        class="elevation-1"
    >
        <template v-slot:top>
        <v-toolbar flat>
            <v-toolbar-title>Master Voucher</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field v-model="keyword" placeholder="Cari..." hide-details></v-text-field>
            <v-spacer></v-spacer>
            <v-dialog scrollable v-model="dialog" max-width="900px">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                        New VOUCHER
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-divider></v-divider>

                    <v-card-text>
                      <v-container>
                        <v-row>
                            <v-col cols="6" sm="6" md="6">
                                <v-text-field outlined dense v-model="editedItem.code" label="Kode" :readonly="editedIndex>-1" @keydown.space.prevent @change="validateCode" hide-details="auto"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field outlined dense v-model="editedItem.voucher_desc" label="Deskripsi" hide-details="auto"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                              <span class="">Penggunaan Voucher</span>
                              <v-radio-group v-model="editedItem.voucher_mode" row dense hide-details="auto">
                                <v-radio label="Adventuraja" value="adventuraja"></v-radio>
                                <v-radio label="External" value="external"></v-radio>
                              </v-radio-group>
                            </v-col>
                            <template v-if="editedItem.voucher_mode=='adventuraja'">
                            <v-col cols="12" sm="12" md="12">
                              <span class="">Voucher Dapat Digunakan Untuk</span>
                              <v-radio-group v-model="editedItem.voucher_usage" row dense hide-details="auto">
                                <v-radio label="Semua" value="all"></v-radio>
                                <v-radio label="Kategori Tertentu" value="category"></v-radio>
                                <v-radio label="Spot Tertentu" value="activity"></v-radio>
                              </v-radio-group>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" v-show="editedItem.voucher_usage=='activity'">
                                <v-autocomplete outlined dense :items="activities" item-text="name" item-value="_id"
                                v-model="editedItem.voucher_usage_id" label="Spot" hide-details="auto"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" v-show="editedItem.voucher_usage=='category'">
                                <v-autocomplete outlined dense :items="categories" item-text="name" item-value="code"
                                v-model="editedItem.voucher_usage_id" label="Kategori" hide-details="auto"></v-autocomplete>
                            </v-col>
                            </template>
                            <v-col cols="6" sm="6" md="6">
                                <v-text-field type="number" outlined dense v-model.number="editedItem.amount" label="Discount Amount" prefix="Rp" @keydown.space.prevent hide-details="auto" @keydown="onChangeAmount" @wheel="$event.target.blur()"></v-text-field>
                            </v-col>
                            <v-col cols="6" sm="6" md="6">
                              <span>Atau</span>
                            </v-col>
                            <v-col cols="6" sm="6" md="6">
                                <v-text-field type="number" outlined dense v-model.number="editedItem.percentage" label="Discount Percentage" suffix="%" @keydown.space.prevent hide-details="auto" @keydown="onChangePercentage" @wheel="$event.target.blur()"></v-text-field>
                            </v-col>
                            <v-col cols="6" sm="6" md="6">
                                <v-text-field outlined dense v-model.number="editedItem.max_amount" type="number" label="Maksimum Diskon" prefix="Rp" hide-details="auto" @wheel="$event.target.blur()" v-show="editedItem.percentage"></v-text-field>
                            </v-col>
                            <v-col cols="6" sm="6" md="6">
                                <v-text-field outlined dense v-model.number="editedItem.validity_days" type="number" label="Masa Berlaku Voucher" suffix="hari" hide-details="auto" @wheel="$event.target.blur()"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field outlined dense v-model.number="editedItem.valid_minimum_purchase" label="Minimum Purchase" hide-details="auto" type="number" prefix="Rp" @wheel="$event.target.blur()"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <span class="">Display Message</span>
                                <tiptap-vuetify class="pb-2" v-model="editedItem.display_message" :extensions="extensions"/>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <span class="">Syarat & Ketentuan</span>
                                <tiptap-vuetify class="pb-2" v-model="editedItem.tnc" :extensions="extensions"/>
                            </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">
                            Cancel
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="save">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
            </v-icon>
            <!-- <v-icon small @click="deleteItem(item)">
                mdi-delete
            </v-icon> -->
        </template>
        <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
                Reset
            </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
</v-card>
</template>

<script>
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'
  export default {
    components: {
      TiptapVuetify
    },
    data: () => ({
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'Kode', align: 'start', sortable: false, value: 'code',
        },
        { text: 'Deskripsi', value: 'voucher_desc' },
        { text: 'Penggunaan', value: 'voucher_mode' },
        { text: 'Berlaku', value: 'voucher_usage' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        code: '',
        voucher_desc: '',
        voucher_mode: '',
        voucher_usage: '',
        amount: '',
        percentage: '',
        validity_days: '',
        voucher_usage_id: '',
        valid_minimum_purchase: '',
        display_message: '',
        tnc: '',
      },
      defaultItem: {
        code: '',
        voucher_desc: '',
        voucher_mode: '',
        voucher_usage: '',
        amount: '',
        percentage: '',
        validity_days: '',
        voucher_usage_id: '',
        valid_minimum_purchase: '',
        display_message: '',
        tnc: '',
      },
      vouchers: [],
      keyword: '',
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],      
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Voucher' : 'Edit Voucher'
      },
      activities () {
        return this.$store.state.activity.objs
      },
      categories () {
        return this.$store.state.category.objs
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      keyword(val) {
        if (!val) val = ''
        this.vouchers = this.$store.state.voucher.objs.filter((el) => {
          return el.voucher_desc.toLowerCase().includes(val.toLowerCase()) ||
              el.code.toLowerCase().includes(val.toLowerCase()) ||
              el.voucher_mode.toLowerCase().includes(val.toLowerCase())
        })
      }

    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        this.$store.dispatch('voucher/getObjs', 'vouchers').then(response => {
            this.vouchers = response
        })
        this.$store.dispatch('activity/getObjs')
        this.$store.dispatch('category/getObjs')
      },
      onChangeAmount() {
        this.editedItem.percentage = ''
        this.editedItem.max_amount = ''
      },
      onChangePercentage() {
        this.editedItem.amount = ''
      },
      validateCode(val) {
        this.editedItem.code = val.replace(/\s/g,'_')
      },

      editItem (item) {
        this.editedIndex = this.vouchers.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.vouchers.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.vouchers.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.vouchers[this.editedIndex], this.editedItem)
        } else {
          this.vouchers.push(this.editedItem)
        }
        delete this.editedItem._id
        console.log(this.editedItem)
        
        this.$store.dispatch('voucher/postObj', {pload:this.editedItem, module:'vouchers'}).then(response => {
            console.log(response)
            this.close()
        }, err => {
            console.log(err)
            this.close()
        })
      },
    },
  }
</script>

<style>
.tiptap-vuetify-editor__content {
  max-height: 150px;
}

.tiptap-vuetify-editor__content p {
    margin-top: 1px!important;
    margin-bottom: 1px!important;
    min-height: 1rem;
}
</style>